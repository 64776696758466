export const GET_ALL_CONTRACTOR_REQUEST = 'GET_ALL_CONTRACTOR_REQUEST';
export const GET_ALL_CONTRACTOR_SUCCESS = 'GET_ALL_CONTRACTOR_SUCCESS';
export const GET_ALL_CONTRACTOR_FAILURE = 'GET_ALL_CONTRACTOR_FAILURE';

export const GET_MOST_LIKED_CONTRACTOR_REQUEST = 'GET_MOST_LIKED_CONTRACTOR_REQUEST';
export const GET_MOST_LIKED_CONTRACTOR_SUCCESS = 'GET_MOST_LIKED_CONTRACTOR_SUCCESS';
export const GET_MOST_LIKED_CONTRACTOR_FAILURE = 'GET_MOST_LIKED_CONTRACTOR_FAILURE';

export const GET_CONTRACTOR_DETAIL_REQUEST = 'GET_CONTRACTOR_DETAIL_REQUEST';
export const GET_CONTRACTOR_DETAIL_SUCCESS = 'GET_CONTRACTOR_DETAIL_SUCCESS';
export const GET_CONTRACTOR_DETAIL_FAILURE = 'GET_CONTRACTOR_DETAIL_FAILURE';