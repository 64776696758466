export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const GET_IN_TOUCH_REQUEST = 'GET_IN_TOUCH_REQUEST';
export const GET_IN_TOUCH_SUCCESS = 'GET_IN_TOUCH_SUCCESS';
export const GET_IN_TOUCH_FAILURE = 'GET_IN_TOUCH_FAILURE';
export const SET_GET_IN_TOUCH = "SET_GET_IN_TOUCH"

export const FETCH_REQUEST = "FETCH_REQUEST";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";
export const SET_FETCH = "SET_FETCH";

export const LIKE_REQUEST = "LIKE_REQUEST";
export const LIKE_SUCCESS = "LIKE_SUCCESS";
export const LIKE_FAILURE = "LIKE_FAILURE";
export const SET_LIKE = "SET_LIKE";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILURE = "SEARCH_FAILURE";
export const SET_SEARCH = "SET_SEARCH";

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const REACT_APP_GOOGLE_MAPS_KEY ="AIzaSyD7xGVb5I8KIPK3w4xEeflybVXAHStp9q4";